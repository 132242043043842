import React from 'react'


import {BrowserRouter as Router,  Routes, Route, Navigate } from 'react-router-dom';
// import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Home from './screens/Home'

require('es6-promise').polyfill();
require('isomorphic-fetch');

export default class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedLanguage: localStorage.getItem('selectedLanguage') || 'US'
        };
    }


    setLanguage = (language) => {
        localStorage.setItem('selectedLanguage', language);
        this.setState({ selectedLanguage: language });
    };

    render(){

        const { selectedLanguage } = this.state;

        return(
            // <Router>

                <Routes>
                    <Route path="/" element={<Home selectedLanguage={'ru'} />} />
                    <Route path="/ru" element={<Home selectedLanguage={'ru'} />} />
                    <Route exact path="/en" element={<Home selectedLanguage={'en'} />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            // </Router>
            //
            // <Router>
            //     <Switch>
            //         <Route exact path="/:lang(en|fr)" element={<Home />} />
            //         <Redirect to={`/${selectedLanguage}`} />
            //     </Switch>
            //     {/* Your application's components */}
            // </Router>

        )
    }
}
