import { Link as LinkScroll } from 'react-scroll';
import React, { useState, useEffect } from 'react';
import '../../../assets/css/footer_style.css';
import footer_logo from '../../../assets/svg/footer_logo.svg';
import {Link} from "react-router-dom";
import footer_social_link_fs from "../../../assets/svg/social_link..svg";
import footer_social_link_telegram from "../../../assets/svg/footer_social_link2.svg";
import footer_social_link_whatsApp from "../../../assets/svg/footer_social_link3.svg";
import logo from "../../../assets/svg/logo.svg";
import fs from "../../../assets/svg/social_link..svg";
import translations from '../../../translations/translations';



const sl_set = {
    infinite: true,
    dots: false,
    arrows: false,
    fade: true,
    waitForAnimate: false,
    pauseOnHover: false,
    beforeChange: (oldIndex, newIndex) => {
        this.setState({
            cur_pr: newIndex + 1,
            sliding: true,
        });
    },
    afterChange: (newIndex) => {
        this.setState({
            sliding: false,
        });
    },
};

export default function Header(props) {
    const [headerScroll, setHeaderScroll] = useState(false);
    const [show_mobile_menu, setShowMobileMenu] = useState(false);
    const { disableBodyScroll } = props;
    const { enableBodyScroll } = props;
    const [language, setLanguage] = useState(props.selectedLanguage)

    const translate = (key) => {
        return translations[language][key] || key; // Return translated text or the key itself if translation not found
    };
    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }


        window.addEventListener('load', handleLoad);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLoad = () => {};

    const handleScroll = (ev) => {
        const scrollTop = window.scrollY;

        if (scrollTop > 0) {
            setHeaderScroll(true);
        } else {
            setHeaderScroll(false);
        }
    };

    const  pathName = () => {
        let name = window.location.pathname;
        console.log(name, 'nameee')
        return name
    }



    return (
        <>

            <div className='footer' id='footer'>
                <div className='footer_wrapper'>
                        <div className='footer_items_wrapper'>
                            <div className='footer_first_item'>
                                <div className="footer_first_item_logo_social_links_wrapper">
                                    <Link to='/'  className='footer_logo_link'>
                                        <img src={footer_logo}/>
                                    </Link>
                                    <div className='footer_first_item_social_links_wrapper'>
                                        <a href="https://www.facebook.com/JustCodeDev"
                                           target='_blank'
                                           className='footer_first_item_social_link'
                                        >
                                            <div className='top_item_info_social_link_svg_parent'>
                                                <img src={footer_social_link_fs} alt=""/>
                                            </div>
                                        </a>
                                        <a href="https://t.me/JustCodeLLC"
                                           className='footer_first_item_social_link'
                                           target='_blank'
                                        >
                                            <img src={footer_social_link_telegram}

                                            />
                                        </a>
                                        <a href="https://wa.me/+37477695677?text=Hi"
                                           className='footer_first_item_social_link'
                                           target='_blank'
                                        >
                                            <img src={footer_social_link_whatsApp}

                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className='footer_first_item_info_box'>
                                    <p className='footer_first_item_info_box_title'>
                                        {/*Наша миссия*/}
                                        {translate('our_mission')}
                                    </p>
                                    <p className='footer_first_item_info_box_main_info'>
                                        {/*Мы делаем ваш бизнес заметным в онлайн-мире. Наша специализация - разработка и продвижение сайтов с помощью SEO-оптимизации, контекстной и таргетированной рекламы. Мы приводим ваш сайт на первые позиции поисковых систем, увеличивая его видимость и привлекая целевую аудиторию.*/}
                                        {translate('our_mission_desc1')}
                                    </p>
                                    <p className='footer_first_item_info_box_main_info2'>
                                        {/*Наша команда профессионалов готова помочь вам достичь успеха и максимального воздействия в онлайн-среде.*/}
                                        {translate('our_mission_desc2')}
                                    </p>
                                </div>
                                <div className='footer_first_item_info_box2'>
                                    <p className='footer_first_item_info_box_title'>
                                        {/*Контакты*/}
                                        {translate('contacts')}
                                    </p>
                                    <div className='footer_first_item_info_box2_address_details'>
                                        <p className='footer_first_item_info_box2_address_details_info'>
                                            {/*Ереван, 26а улица Мовсеса Хоренаци*/}
                                            {translate('contact_address')}
                                        </p>
                                        <p className='footer_first_item_info_box2_address_details_info'>
                                            {translate('contact_phone')}: <a href="tel:+37499695677">+374 (33) 28-28-23</a>
                                        </p>
                                        <p className='footer_first_item_info_box2_address_details_info'>
                                            {translate('contact_email')}: <a href="mailto:justcodedevcompany@gmail.com">justcodedevcompany@gmail.com</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='footer_second_item'>
                                <p className='footer_second_item_info'>
                                    {/*Digital-агентство JustCode Development Company © 2018 — 2023. Все права защищены.*/}
                                    {translate('footer_text')}
                                </p>
                                <div className='footer_second_item_links_wrapper'>

                                    <LinkScroll to='services' smooth={true}  duration={500} offset={-160} className="footer_second_item_link">
                                        {/*Услуги*/}
                                        {translate('services')}
                                    </LinkScroll>
                                    <LinkScroll to='mobile_applications' smooth={true} duration={500} offset={-150} className="footer_second_item_link">
                                        {/*Портфолио*/}
                                         {translate('portfolio')}
                                    </LinkScroll>

                                    <LinkScroll to='our_team' smooth={true} duration={500} offset={-160} className="footer_second_item_link">
                                        {/*Команда*/}
                                        {translate('team')}
                                    </LinkScroll>
                                    <LinkScroll to='footer' smooth={true} duration={500} offset={2020} className="footer_second_item_link">
                                        {/*Контакты*/}
                                        {translate('contacts')}
                                    </LinkScroll>
                                </div>
                            </div>
                        </div>
                </div>

            </div>

        </>
    );
}
