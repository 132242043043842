import React, { useState, useEffect } from 'react';
 import { Link } from 'react-router-dom';
 import { Link as LinkScroll } from 'react-scroll';
 import '../../assets/css/home_style.css';
 import Header from "./includes/Header";
 import Footer from "./includes/Footer";
 import fs from  '../../assets/svg/social_link..svg';
 import telegram from  '../../assets/svg/telegram.svg';
 import whatsapp from  '../../assets/svg/whatsapp.svg';
 import services_icon1 from  '../../assets/svg/services_icon1.svg';
 import services_icon2 from  '../../assets/svg/services_icon2.svg';
 import services_icon3 from  '../../assets/svg/services_icon3.svg';
 import services_icon4 from  '../../assets/svg/services_icon4.svg';
 import services_icon5 from  '../../assets/svg/services_icon5.svg';
 import services_icon6 from  '../../assets/svg/services_icon6.svg';
import close_icon from "../../assets/svg/close_icon.svg";
import logo from "../../assets/svg/logo.svg";
import { Helmet } from 'react-helmet';
import telegram_iocn from  '../../assets/svg/telegram_icon.svg';
import whatsapp_icon from  '../../assets/svg/whatsapp_icon.svg';

import translations from '../../translations/translations';


 let sl_set = {};

 export default function Home (props) {
     const [headerScroll, setHeaderScroll] = useState(false);
     const [sliding, setSliding] = useState(false);
     const [show_get_consultation_popup, setShowGetConsultationPopup] = useState(false);
     const [waiting_for_you_popup, setWaitingForYouPopup] = useState(false);
     const [select_service_type1, setSelectServiceType1] = useState('');
     const [select_service_type2, setSelectServiceType2] = useState('');
     const [select_service_type3, setSelectServiceType3] = useState('');
     const [select_service_type4, setSelectServiceType4] = useState('');
     const [select_service_type5, setSelectServiceType5] = useState('');
     const [select_error, setSelectError] = useState(false);
     const [success_popup, setSuccessPopup] = useState(false);

     const [language, setLanguage] = useState(props.selectedLanguage);

     const [name, setName] = useState('');
     const [name_error_text, setNameErrorText] = useState('');
     const [name_error, setNameError] = useState(false);

     const [phone, setPhone] = useState('');
     const [phone_error_text, setPhoneErrorText] = useState('');
     const [phone_error, setPhoneError] = useState(false);

     const [email, setEmail] = useState('');
     const [email_error_text, setEmailErrorText] = useState('');
     const [email_error, setEmailError] = useState(false);

     const translate = (key) => {
         return translations[language][key] || key; // Return translated text or the key itself if translation not found
     };

     const services = [
         {
             id: 1,
             img: services_icon1,
             title: translate('service_development_app_title'),
             info: translate('service_development_app_description')
         },
         {
             id: 2,
             img: services_icon2,
             title: translate('service_development_website_title'),
             info: translate('service_development_website_description')
         },
         {
             id: 3,
             img: services_icon3,
             title: translate('service_development_seo_title'),
             info: translate('service_development_seo_description')

         },
         {
             id: 4,
             img: services_icon4,
             title: translate('service_development_reklam_title'),
             info: translate('service_development_reklam_description')

         },
         {
             id: 5,
             img: services_icon5,
             title: translate('service_development_texpodderzhka_title'),
             info: translate('service_development_texpodderzhka_description')

         },
         {
             id: 6,
             img: services_icon6,
             title: translate('service_development_courses_title'),
             info: translate('service_development_courses_description')
         },
     ];
     const mobile_applications = [
         {
             id: 1,
             name: 'STECH',
             img: require('../../assets/img/mobile_img1.webp'),
             link: 'https://play.google.com/store/apps/details?id=com.JustCodeLLC.Stech',
             description: 'Мобильный агрегатор для заказа спецтехники и грузоперевозок.'
         },
         {
             id: 2,
             name: 'PassimPay',
             img: require('../../assets/img/passimpay_img3.png'),
             link: '',
             description: ''
         },
         {
             id: 3,
             name: 'BandRate',
             img: require('../../assets/img/bandrate_img2.png'),
             link: 'https://play.google.com/store/apps/details?id=com.JustCode.BandRateSmart',
             description: 'Умные часы и фитнес браслеты BandRate Smart.'
         },
         {
             id: 4,
             name: 'DreamsLab',
             img: require('../../assets/img/dreamslab.png'),
             link: 'https://dreamslab.am/',
             description: 'Dreamslab - система онлайн обучения.'
         },
         {
             id: 5,
             name: 'Sentry',
             img: require('../../assets/img/sentry.png'),
             link: 'https://sentryms.com/',
             description: 'Единое окно для управления транспортной логистикой.'
         },
         {
             id: 6,
             name: 'SuitesNl',
             img: require('../../assets/img/suites2.png'),
             link:'https://suites.nl/',
             description: 'Suites.nl — это платформа для поиска подходящего отеля.'
         },

     ];
     const web_pages = [
         {
             id: 1,
             name: 'STECH',
             img: require('../../assets/img/mobile_img1.webp')
         },
         {
             id: 2,
             name: 'PassimPay',
             img: require('../../assets/img/mobile_img2.png')
         },
         {
             id: 3,
             name: 'JustEarn',
             img: require('../../assets/img/mobile_img3.webp')
         },
         {
             id: 4,
             name: 'STECH',
             img: require('../../assets/img/mobile_img1.webp')
         },
         {
             id: 5,
             name: 'PassimPay',
             img: require('../../assets/img/mobile_img2.png')
         },
         {
             id: 6,
             name: 'JustEarn',
             img: require('../../assets/img/mobile_img3.webp')
         },
     ];
     const our_team = [
         {
             id: 1,
             name: translate('artem_hakobjanyan'),
             img: require('../../assets/img/our_team_img1.png'),
             position: translate('artem_hakobjanyan_role')
         },
         {
             id: 2,
             name: translate('vitaly_hakobjanyan'),
             img: require('../../assets/img/our_team_img2.png'),
             position: translate('vitaly_hakobjanyan_role')
         },
         {
             id: 3,
             name: translate('rima_hayrapetyan'),
             img: require('../../assets/img/our_team_img100.png'),
             position: translate('rima_hayrapetyan_role')
         },
         {
             id: 10,
             name: translate('ani_koshkaryan'),
             img: require('../../assets/img/our_team_img30.png'),
             position: translate('ani_koshkaryan_role')
         },
         {
             id: 3,
             name: translate('norayr_asoyan'),
             img: require('../../assets/img/our_team_img3.png'),
             position: translate('norayr_asoyan_role')
         },

         {
             id: 4,
             name: translate('lianna_hakobyan'),
             img: require('../../assets/img/our_team_img13.png'),
             position: translate('lianna_hakobyan_role')
         },
         {
             id: 5,
             name: translate('mekhak_poghosyan'),
             img: require('../../assets/img/our_team_img12.png'),
             position: translate('mekhak_poghosyan_role')
         },

         {
             id: 6,
             name: translate('shogik_galoyan'),
             img: require('../../assets/img/our_team_img5.png'),
             position: translate('shogik_galoyan_role')
         },

         {
             id: 8,
             name: translate('lilit_mroyan'),
             img: require('../../assets/img/our_team_img7.png'),
             position: translate('lilit_mroyan_role')
         },
         {
             id: 9,
             name: translate('mane_gevorgyan'),
             img: require('../../assets/img/our_team_member34.png'),
             position: translate('mane_gevorgyan_role')
         },

         {
             id: 11,
             name: translate('arman_alekyan'),
             img: require('../../assets/img/our_team_img8.png'),
             position: translate('arman_alekyan_role')
         },
         {
             id: 12,
             name: translate('karen_aroyan'),
             img: require('../../assets/img/our_team_img9.png'),
             position: translate('karen_aroyan_role')
         },

     ];


     const [text, setText] = useState('');
     const fullText = translate('auto_complite_text');
     const delay = 100; // Delay in milliseconds between each character

     useEffect(() => {
         let currentIndex = 0;

         const timer = setInterval(() => {
             if (currentIndex < fullText.length) {
                 setText((prevText) => prevText + fullText[currentIndex]);
                 currentIndex++;
             } else {
                 clearInterval(timer);
             }
         }, delay);

         return () => clearInterval(timer);
     }, []);

     useEffect(() => {
         if ('scrollRestoration' in window.history) {
             window.history.scrollRestoration = 'manual';
         }

         window.addEventListener('load', handleLoad);
         window.addEventListener('scroll', handleScroll);

         return () => {
             window.removeEventListener('load', handleLoad);
             window.removeEventListener('scroll', handleScroll);
         };
     }, []);

     useEffect(() => {
         sl_set = {
             infinite: true,
             dots: false,
             arrows: false,
             fade: true,
             waitForAnimate: false,
             /*autoplaySpeed: 3000,
             autoplay: true,*/
             pauseOnHover: false,
             beforeChange: (oldIndex, newIndex) => {
                 setSliding(true);
                 setCurPr(newIndex + 1);
             },
             afterChange: (newIndex) => {
                 setSliding(false);
             }
         };
     }, []);

     const handleLoad = () => {
     };

     const handleScroll = (ev) => {
         let scrollTop = window.scrollY;

         if (scrollTop > 0) {
             setHeaderScroll(true);
         } else {
             setHeaderScroll(false);
         }
     };

     const disableBodyScroll = () => {
         document.body.style.overflow = "hidden";
     };

     const enableBodyScroll = () => {
         document.body.style.overflow = "auto";
     };

     const scrollToElement = (el, pos = 0) => {
         window.scrollTo({
             top: el.offsetTop + pos,
             behavior: 'smooth',
         });
     };


     const [showButton, setShowButton] = useState(false);

     const handleScroll2 = () => {
         if (window.scrollY > 700) {
             setShowButton(true);
         } else {
             setShowButton(false);
         }
     };


     const scrollToTop = () => {
         window.scrollTo({ top: 0, behavior: 'smooth' });
     };

     useEffect(() => {
         window.addEventListener('scroll', handleScroll2);
         return () => {
             window.removeEventListener('scroll', handleScroll2);
         };
     }, []);


     const handleOptionChange1 = () => {
         setSelectServiceType1(!select_service_type1);
         if (select_service_type1 != '') {
              setSelectError(false)
         }

     };

     const handleOptionChange2 = () => {
         setSelectServiceType2(!select_service_type2);
         if (select_service_type2 != '') {
             setSelectError(false)
         }
     };

     const handleOptionChange3 = () => {
         setSelectServiceType3(!select_service_type3);
         if (select_service_type3 != '') {
             setSelectError(false)
         }
     };

     const handleOptionChange4 = () => {
         setSelectServiceType4(!select_service_type4);
         if (select_service_type4 != '') {
             setSelectError(false)
         }

     };

     const handleOptionChange5 = () => {
         setSelectServiceType5(!select_service_type5);
         if (select_service_type5 != '') {
             setSelectError(false)
         }
     };

    const validateEmail = (email) => {
         // Regular expression for email validation
         const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

         return emailRegex.test(email);
     };

     const request = async () => {

         let isValidEmail = validateEmail(email);

         if (name.length == 0 || phone.length == 0 || email.length == 0 || isValidEmail === false) {
                if (name.length == 0) {
                     setNameError(true)
                } else {
                    setNameError(false)
                }
             if (phone.length == 0) {
                 setPhoneError(true)
             } else {
                 setPhoneError(false)
             }
             if (email.length == 0) {
                 setEmailError(true)
             } else if (isValidEmail === false) {
                 setEmailError(true)
             } else {
                 setEmailError(false)
             }

         } else {
             try {
                 fetch(`https://admin.justcode.am/api/new_request`, {
                     method: 'POST',
                     headers: {
                         'Accept': 'application/json',
                         'Content-Type': 'application/json',
                     },
                     body: JSON.stringify({
                         name: name,
                         email: email,
                         phone: phone,
                         attribute: ''
                     })

                 }).then((response) => {
                     return response.json()
                 }).then((response) => {

                     console.log(response, 'request response')
                     if (response.hasOwnProperty('status')){
                        if (response.status === true) {
                            if (response.hasOwnProperty('message')){
                                if (response.message == 'Message Created') {
                                    setShowGetConsultationPopup(false)
                                    setSuccessPopup(true)
                                    disableBodyScroll()
                                }
                            }
                        }
                     }
                 })
             } catch (e) {
                 console.log(e)

             }
         }


     }



         return (
         <>

             <div className='main_wrapper'>
                 {showButton && (
                     <button className="upButton" onClick={scrollToTop}>
                     </button>
                 )}
                 <main className='main_container'>
                     <div className='header_top_wrapper'>
                         <Header disableBodyScroll={disableBodyScroll} enableBodyScroll={enableBodyScroll} selectedLanguage={props.selectedLanguage} setShowGetConsultationPopup={setShowGetConsultationPopup} />
                         <section className='top' id='top'>
                             <div className='top_img_main'>
                                 <img src={require('../../assets/img/back_img.png')}/>
                             </div>
                             <div className='top_wrapper'>
                                 <div className='top_items_wrapper'>
                                     <div className='top_item_info_box'>
                                         <h1 className='top_item_info_box_main_title'>
                                             {/*Разработка сайтов*/}
                                             {/*и мобильных приложений*/}
                                             {translate('top_title')}

                                         </h1>
                                         <p className='top_item_info_box_info1'>
                                             {text}
                                         </p>

                                         <div className='top_item_images_box' id='top_item_images_box_mobile'>
                                             <img src={require('../../assets/img/top_img2.png')}/>
                                         </div>
                                         <div className='top_item_info_name_position_social_links_main_wrapper'>
                                             <div className='top_item_info_name_position_box'>
                                                 <p className='top_item_info_name'>
                                                     {/*Артем Акобджанян*/}
                                                     {translate('artem_hakobjanyan')}
                                                 </p>
                                                 <p className='top_item_info_name2'>
                                                     {/*Мобильный разработчик с командой*/}
                                                     {translate('mob_dev_with_team')}

                                                 </p>
                                             </div>

                                             <div className='top_item_info_social_links_wrapper'>
                                                 <a href="https://www.facebook.com/JustCodeDev" target='_blank' className='top_item_info_social_link'>
                                                     <div className='top_item_info_social_link_svg_parent'>
                                                         <img src={fs} alt=""/>
                                                     </div>

                                                 </a>
                                                 <a href="https://t.me/JustCodeLLC" target='_blank'  className='top_item_info_social_link'>
                                                     <img src={telegram} alt="" />
                                                 </a>
                                                 <a href="https://wa.me/+37477695677?text=Hi" className='top_item_info_social_link' target='_blank' >
                                                     <img src={whatsapp} alt="" style={{
                                                         width: 34,
                                                         position: 'relative',
                                                         top: 3
                                                     }}/>
                                                 </a>
                                             </div>

                                         </div>

                                         <div className='top_get_consultation_btn_phone_link_wrapper'>
                                             {/*<a className='top_phone_link' href="tel:+374 (77) 69-56-77">*/}
                                             {/*    +374 (99) 69-56-77*/}
                                             {/*</a>*/}

                                             {/*<div className='top_item_info_social_links_wrapper'>*/}
                                             {/*    <a href="https://www.facebook.com/JustCodeDev" target='_blank' className='top_item_info_social_link'>*/}
                                             {/*        <div className='top_item_info_social_link_svg_parent'>*/}
                                             {/*            <img src={fs} alt=""/>*/}
                                             {/*        </div>*/}

                                             {/*    </a>*/}
                                             {/*    <a href="https://t.me/JustCodeLLC" target='_blank'  className='top_item_info_social_link'>*/}
                                             {/*        <img src={telegram} alt="" />*/}
                                             {/*    </a>*/}
                                             {/*    <a href="https://wa.me/+37477695677?text=Hi" className='top_item_info_social_link' target='_blank' >*/}
                                             {/*        <img src={whatsapp} alt="" style={{*/}
                                             {/*            width: 34,*/}
                                             {/*            position: 'relative',*/}
                                             {/*            top: 3*/}
                                             {/*        }}/>*/}
                                             {/*    </a>*/}
                                             {/*</div>*/}

                                             {/*<button className='top_get_consultation_btn'   onClick={() => {*/}
                                             {/*    disableBodyScroll()*/}
                                             {/*    setShowGetConsultationPopup(true)*/}
                                             {/*}}>*/}
                                             {/*    Получить консультацию*/}
                                             {/*</button>*/}

                                         </div>
                                     </div>
                                     <div className='top_item_images_box' id='top_item_images_box_desktop'>
                                         <img src={require('../../assets/img/top_img2.png')}/>
                                     </div>
                                 </div>
                             </div>
                         </section>
                     </div>
                     <section className='services' id='services'>
                         <div className='services_wrapper'>

                             <h1 className="services_title">
                                 {/*Наши <span>экспертные услуги</span> для вашего успеха!*/}
                                 {translate('services_title1_part')} <span>{translate('services_title2_part')}</span> {translate('services_title3_part')}
                             </h1>
                             <div className='services_items_wrapper'>
                                 {services.map((item, index) => {

                                     return (
                                         <div className='services_item' key={index}>
                                             <div className='services_item_img'>
                                                 <img src={item.img} />
                                             </div>
                                             <p className='services_item_title'>{item.title}</p>
                                             <p className='services_item_info'>{item.info}</p>
                                         </div>

                                     );
                                 })}


                             </div>
                         </div>
                     </section>
                     <section className='our_mobile_applications' id='mobile_applications'>
                        <div className='our_mobile_applications_wrapper'>
                            {/*<h1 className='our_mobile_applications_title'>НАШИ РАБОТЫ</h1>*/}
                            {/*<h1 className="our_mobile_applications_title">Наши <span style={{color:'#FF6501'}}>работы</span> </h1>*/}
                            <h1 className="our_mobile_applications_title">{translate('portfolio_title1')} <span style={{color:'#FF6501'}}>{translate('portfolio_title2')}</span> </h1>
                            <p className="our_team_info">
                                {/*Портфолио: Отражение наших успехов и клиентской удовлетворенности.*/}
                                {translate('portfolio_description')}

                            </p>
                            <div className='our_mobile_applications_items_wrapper'>
                                {mobile_applications.map((item, index) => {

                                    return (
                                        <div  className='our_mobile_applications_item' key={index}>
                                                <div className='our_mobile_applications_item_name'>
                                                    <p className='our_mobile_applications_item_name_text'>{item.name}</p>
                                                    <p  className='our_mobile_applications_item_desc_text'>{item.description}</p>
                                                    {item.id != 2 &&
                                                        <a target='_blank' rel="nofollow" href={item.link}  className='our_mobile_applications_item_more'>
                                                            {translate('more')}
                                                        </a>
                                                    }

                                                </div>
                                                <div className='our_mobile_applications_item_img'>
                                                    <img src={item.img}/>
                                                </div>
                                        </div>

                                    );
                                })}


                            </div>
                        </div>
                     </section>
                     {/*<section className='our_mobile_applications' id='web_pages'>*/}
                     {/*   <div className='our_mobile_applications_wrapper'>*/}
                     {/*       <h1 className='our_mobile_applications_title'>Web сайты</h1>*/}
                     {/*       <div className='our_mobile_applications_items_wrapper'>*/}
                     {/*           {web_pages.map((item, index) => {*/}

                     {/*               return (*/}
                     {/*                   <div className='our_mobile_applications_item' key={index}>*/}
                     {/*                           <p className='our_mobile_applications_item_name'>{item.name}</p>*/}
                     {/*                           <div className='our_mobile_applications_item_img'>*/}
                     {/*                               <img src={item.img}/>*/}
                     {/*                           </div>*/}
                     {/*                   </div>*/}

                     {/*               );*/}
                     {/*           })}*/}


                     {/*       </div>*/}
                     {/*   </div>*/}
                     {/*</section>*/}
                     <section className="our_team" id='our_team'>
                         <div className='our_team_wrapper'>
                             <div className='our_team_title_info_wrapper'>
                                 {/*<h1 className="our_team_title">Наша <span style={{color:'#FF6501'}}>команда</span> </h1>*/}
                                 {/*<p className="our_team_info">*/}
                                 {/*    Вместе мы сила: наша команда экспертов готова воплотить ваши амбиции в реальность и преуспеть вместе с вами*/}
                                 {/*</p>*/}

                                 <h1 className="our_team_title">{translate('teams_title1')} <span style={{color:'#FF6501'}}>{translate('teams_title2')}</span> </h1>
                                 <p className="our_team_info">
                                     {translate('teams_description')}
                                 </p>
                             </div>

                             <div className='our_team_items_wrapper'>
                                 {our_team.map((item, index) => {

                                     return (
                                         <div className='our_team_item' key={index}>
                                             <div className='our_team_item_img'>
                                                 <img src={item.img}
                                                      style={{
                                                          top: item.id == 1 ?  '19px' : item.id == 2 ? '10px' : item.id == 3 ? '17px' : item.id == 4 ? '14px' :  item.id == 5 ? '20px' : item.id == 6 ? '13px' : item.id == 7 ? '-86px' : item.id == 8 ? '0' : item.id == 9 ? '12px' : item.id == 10 ? '9px' : item.id == 11 ? '21px' : item.id == 12 ? '-16px' : '0',
                                                          width: item.id == 1 ?  '79%' : item.id == 2 ? '100%' : item.id == 3 ? '100%' : item.id == 4 ? '83%' : item.id == 5 ? '110%' : item.id == 6 ? '100%' : item.id == 7 ? '100%' : item.id == 8 ? '112%' : item.id == 9 ? '81%' : item.id == 10 ? '80%' : item.id == 11 ? '100%' : item.id == 12 ? '113%' : '0',
                                                          left: item.id == 1 ?  '19px' : item.id == 2 ? '0' : item.id == 3 ? '0' : item.id == 4 ? '7px' : item.id == 5 ? '5px' :  item.id == 6 ? '7px' : item.id == 7 ? '0' : item.id == 8 ? '0' : item.id == 9 ? '16px' : item.id == 10 ? '7px' : item.id == 11 ? '-3px' : item.id == 12 ? '12px' : '0',
                                                          transform: item.id == 10 ? 'rotate(-9deg)' : item.id == 5 ? 'rotate(9deg)' : ''
                                                      }}
                                                 />
                                             </div>
                                             <p className='our_team_item_title'>{item.name}</p>
                                             <p className='our_team_item_position'>{item.position}</p>
                                         </div>

                                     );
                                 })}
                             </div>
                         </div>
                     </section>
                     <section className='submit_your_application' id='submit_your_application'>
                         <div className='submit_your_application_wrapper'>
                             <div className='submit_your_application_wrapper_img'>
                                 <img src={require('../../assets/img/submit_img.png')} alt=""/>
                             </div>
                             <div className='submit_your_application_info_item'>
                                    <p className='submit_your_application_info_item_title'>
                                        {/*Доверьте нам все задачи и смело сосредоточьтесь на своем бизнесе, остальное мы возьмем на себя!*/}
                                        {translate('doverte_nam')}
                                    </p>
                                 <button className='submit_your_application_info_item_btn' onClick={() => {
                                     disableBodyScroll()
                                     setShowGetConsultationPopup(true)
                                 }}>
                                     {/*Оставить заявку*/}
                                     {translate('submit_your_application')}
                                 </button>
                             </div>
                             <div className='submit_your_application_img'>
                                 <img src={require('../../assets/img/sumbit_img2.png')}/>
                             </div>
                         </div>
                     </section>
                     {/*<section className='join_our_team' id='join_our_team'>*/}
                     {/*    <div className='join_our_team_wrapper'>*/}
                     {/*        <div className='join_our_team_wrapper_img'>*/}
                     {/*            <img src={require('../../assets/img/submit_img.png')} alt=""/>*/}
                     {/*        </div>*/}
                     {/*        <div className='join_our_team_info_item'>*/}
                     {/*            <p className='join_our_team_info_item_title'>
                     {/*                Вы опытный, честный, ответственный, не ленивый*/}
                     {/*                и хотите к нам в команду?
                     {/*            </p>
                     {/*            <button className='join_our_team_info_item_btn' onClick={() => {*/}
                     {/*                disableBodyScroll()*/}
                     {/*                setWaitingForYouPopup(true)*/}
                     {/*            }}>*/}
                     {/*                Ждем вас*/}
                     {/*            </button>*/}
                     {/*        </div>*/}
                     {/*        <div className='join_our_team_item_img'>*/}
                     {/*            <img src={require('../../assets/img/join_our_team_img.png')}/>*/}
                     {/*        </div>*/}
                     {/*    </div>*/}
                     {/*</section>*/}
                     <section className="map" id='map'>
                         <div className="map_wrapper">
                             <iframe
                                 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2785.936481366687!2d44.51124304079535!3d40.17272735278994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abdaa245b478d%3A0x8a1cb97cd19e54a5!2sJDC%20(JustCode%20Development%20Company)!5e0!3m2!1sru!2sam!4v1691428920515!5m2!1sru!2sam" allowFullScreen="" loading="lazy"
                                 referrerPolicy="no-referrer-when-downgrade"></iframe>
                         </div>
                     </section>

                 </main>


                 {show_get_consultation_popup &&
                 //    <div className='show_get_consultation_popup'>
                 //     <div className='show_get_consultation_popup_wrapper_parent'>
                 //         <div className='show_get_consultation_popup_wrapper'>
                 //             <button className='show_get_consultation_popup_close_btn'
                 //                     onClick={() =>
                 //                     {
                 //                         enableBodyScroll()
                 //                         setShowGetConsultationPopup(false)
                 //                     }
                 //                     }>
                 //                 <img src={close_icon} alt=""/>
                 //             </button>
                 //             <div className='show_get_consultation_popup_logo'>
                 //                 <img src={logo} alt=""/>
                 //             </div>
                 //
                 //             <div className='show_get_consultation_popup_body_wrapper'>
                 //                 <div className='show_get_consultation_popup_body'>
                 //                     <div className='show_get_consultation_popup_input_field_wrapper'>
                 //                         <input type="text" className='show_get_consultation_popup_input_field' placeholder='Имя'/>
                 //                     </div>
                 //                     <div className='show_get_consultation_popup_input_field_wrapper'>
                 //                         <input type="text" className='show_get_consultation_popup_input_field' placeholder='Электронная почта'/>
                 //                     </div>
                 //                     <div className='show_get_consultation_popup_input_field_wrapper'>
                 //                         <input type="text" className='show_get_consultation_popup_input_field' placeholder='Номер телефона'/>
                 //                     </div>
                 //                     <div className='show_get_consultation_popup_input_field_wrapper'>
                 //                         <input type="text" className='show_get_consultation_popup_input_field' placeholder='Название вашей организации'/>
                 //                     </div>
                 //                     <div className='show_get_consultation_popup_input_field_wrapper'>
                 //                         <input type="text" className='show_get_consultation_popup_input_field' placeholder='Чем вы занимаетесь?'/>
                 //                     </div>
                 //                     <div className='show_get_consultation_popup_checkbox_inputs_wrapper'>
                 //                         <p className='show_get_consultation_popup_check_input_title_main'>Выберите услугу</p>
                 //                         <div className='show_get_consultation_popup_checkbox_inputs_wrapper_parent'>
                 //                             <label className='container1'>
                 //                                 <input
                 //                                     type='checkbox'
                 //                                     value={select_service_type1}
                 //                                     checked={select_service_type1}
                 //                                     onChange={handleOptionChange1}
                 //                                 />
                 //
                 //
                 //                                 <span className='checkmark1'></span>
                 //                                 <p className='show_get_consultation_popup_check_input_title'>Разработка сайта</p>
                 //                             </label>
                 //                             <label className='container2'>
                 //                                 <input
                 //                                     type='checkbox'
                 //                                     value={select_service_type2}
                 //                                     checked={select_service_type2}
                 //                                     onChange={handleOptionChange2}
                 //                                 />
                 //
                 //
                 //                                 <span className='checkmark2'></span>
                 //                                 <p className='show_get_consultation_popup_check_input_title'>Разработка фирменного стиля</p>
                 //                             </label>
                 //                             <label className='container3'>
                 //                                 <input
                 //                                     type='checkbox'
                 //                                     value={select_service_type3}
                 //                                     checked={select_service_type3}
                 //                                     onChange={handleOptionChange3}
                 //                                 />
                 //
                 //
                 //                                 <span className='checkmark3'></span>
                 //                                 <p className='show_get_consultation_popup_check_input_title'>Продвижение сайта</p>
                 //                             </label>
                 //                             <label className='container4'>
                 //                                 <input
                 //                                     type='checkbox'
                 //                                     value={select_service_type4}
                 //                                     checked={select_service_type4}
                 //                                     onChange={handleOptionChange4}
                 //                                 />
                 //
                 //
                 //                                 <span className='checkmark4'></span>
                 //                                 <p className='show_get_consultation_popup_check_input_title'>Мобильное приложение</p>
                 //                             </label>
                 //                             <label className='container5'>
                 //                                 <input
                 //                                     type='checkbox'
                 //                                     value={select_service_type5}
                 //                                     checked={select_service_type5}
                 //                                     onChange={handleOptionChange5}
                 //                                 />
                 //
                 //
                 //                                 <span className='checkmark5'></span>
                 //                                 <p className='show_get_consultation_popup_check_input_title'>Другое</p>
                 //                             </label>
                 //                         </div>
                 //                     </div>
                 //                 </div>
                 //                 <div className='show_get_consultation_popup_body_wrapper_right'>
                 //
                 //                 </div>
                 //             </div>
                 //
                 //             <button className='send_get_consultation_btn'>
                 //                 Отправить запрос
                 //             </button>
                 //         </div>
                 //
                 //     </div>
                 // </div>

                     <div className='show_get_consultation_popup'>
                         <div className='show_get_consultation_popup_wrapper_parent'>
                             <div className='show_get_consultation_popup_wrapper'>
                                 <button className='show_get_consultation_popup_close_btn'
                                         onClick={() =>
                                         {
                                             // props.enableBodyScroll()
                                             setShowGetConsultationPopup(false)
                                             enableBodyScroll()
                                         }
                                         }>
                                     <img src={close_icon} alt=""/>
                                 </button>
                                 <div className='show_get_consultation_popup_logo'>
                                     <img src={logo} alt=""/>
                                 </div>

                                 <form className='show_get_consultation_popup_body_wrapper'>
                                     <div className='show_get_consultation_popup_body'>
                                         <h1 className='show_get_consultation_popup_body_title'>
                                             {/*ОСТАВЬТЕ ЗАЯВКУ*/}
                                             {translate('submit_your_application')}

                                         </h1>
                                         <div className='show_get_consultation_popup_input_field_wrapper'>
                                             <div className='form_icon_wrapper'>
                                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="person"><g data-name="Layer 2"><path d="M12 11a4 4 0 1 0-4-4 4 4 0 0 0 4 4zm6 10a1 1 0 0 0 1-1 7 7 0 0 0-14 0 1 1 0 0 0 1 1z" data-name="person"></path></g></svg>
                                             </div>

                                             <input type="text" className='show_get_consultation_popup_input_field' name='name'  style={{borderBottom: name_error ? '1px solid red' : '1px solid #82828269'}} placeholder={translate('form_your_name')}
                                                    onChange={(e) =>
                                                        {
                                                            setName(e.target.value)
                                                            setNameError(false)
                                                        }
                                                    }
                                             />
                                         </div>
                                         <div className='show_get_consultation_popup_input_field_wrapper'>

                                             <div className='form_icon_wrapper form_icon_wrapper_email'>
                                                 <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 96" id="email"><path d="M0 11.283V8a8 8 0 0 1 8-8h112a8 8 0 0 1 8 8v3.283l-64 40zm66.12 48.11a4.004 4.004 0 0 1-4.24 0L0 20.717V88a8 8 0 0 0 8 8h112a8 8 0 0 0 8-8V20.717z" data-name="Layer 2"></path></svg>
                                             </div>

                                             <input type="email" className='show_get_consultation_popup_input_field' style={{borderBottom: email_error ? '1px solid red' : '1px solid #82828269'}}  name='email' placeholder='E-mail'
                                                    onChange={(e) =>
                                                        {
                                                            setEmail(e.target.value)
                                                            setEmailError(false)
                                                        }
                                                    }
                                             />
                                         </div>
                                         <div className='show_get_consultation_popup_input_field_wrapper'>

                                             <div className='form_icon_wrapper form_icon_wrapper_email'>
                                                 <svg xmlns="http://www.w3.org/2000/svg" id="phone" x="0" y="0" version="1.1" viewBox="0 0 29 29" ><path d="m20.914 17.743-2.091 1.178a1.319 1.319 0 0 1-1.58-.217l-6.979-6.979a1.32 1.32 0 0 1-.217-1.58l1.178-2.091a1.978 1.978 0 0 0-.325-2.37L7.766 2.55a1.978 1.978 0 0 0-2.798 0L3.545 3.972a5.276 5.276 0 0 0-.793 6.446l.714 1.19a41.36 41.36 0 0 0 14.946 14.631l.141.081c2.102 1.201 4.699.851 6.382-.831l1.486-1.486a1.978 1.978 0 0 0 0-2.798l-3.136-3.136a1.978 1.978 0 0 0-2.371-.326z"></path></svg>
                                             </div>
                                             <input type="number" className='show_get_consultation_popup_input_field' style={{borderBottom: phone_error ? '1px solid red' : '1px solid #82828269'}}  name='phone' placeholder={translate('form_phone')}
                                                    onChange={(e) => {
                                                        setPhone(e.target.value)
                                                        setPhoneError(false)
                                                    }
                                                    }

                                             />
                                         </div>
                                         {/*<div className='show_get_consultation_popup_input_field_wrapper'>*/}
                                         {/*    <input type="text" className='show_get_consultation_popup_input_field' placeholder='Название вашей организации'/>*/}
                                         {/*</div>*/}
                                         {/*<div className='show_get_consultation_popup_input_field_wrapper'>*/}
                                         {/*    <input type="text" className='show_get_consultation_popup_input_field' placeholder='Чем вы занимаетесь?'/>*/}
                                         {/*</div>*/}
                                         <div className='show_get_consultation_popup_checkbox_inputs_wrapper'>
                                             <p className='show_get_consultation_popup_check_input_title_main'>
                                                 {/*Выберите услугу*/}
                                                 {translate('choose_a_service')}
                                             </p>
                                             <div className='show_get_consultation_popup_checkbox_inputs_wrapper_parent'>
                                                 <label className='container1'>
                                                     <input
                                                         type='checkbox'
                                                         value={select_service_type1}
                                                         checked={select_service_type1}
                                                         onChange={handleOptionChange1}

                                                     />


                                                     <span className='checkmark1'></span>
                                                     <p className='show_get_consultation_popup_check_input_title'>
                                                         {/*Разработка сайта*/}
                                                         {translate('form_service1')}
                                                     </p>
                                                 </label>
                                                 <label className='container2'>
                                                     <input
                                                         type='checkbox'
                                                         value={select_service_type2}
                                                         checked={select_service_type2}
                                                         onChange={handleOptionChange2}
                                                     />


                                                     <span className='checkmark2'></span>
                                                     <p className='show_get_consultation_popup_check_input_title'>
                                                         {/*Разработка фирменного стиля*/}
                                                         {translate('form_service2')}
                                                     </p>
                                                 </label>
                                                 <label className='container3'>
                                                     <input
                                                         type='checkbox'
                                                         value={select_service_type3}
                                                         checked={select_service_type3}
                                                         onChange={handleOptionChange3}
                                                     />


                                                     <span className='checkmark3'></span>
                                                     <p className='show_get_consultation_popup_check_input_title'>
                                                         {/*Продвижение сайта*/}
                                                         {translate('form_service3')}
                                                     </p>
                                                 </label>
                                                 <label className='container4'>
                                                     <input
                                                         type='checkbox'
                                                         value={select_service_type4}
                                                         checked={select_service_type4}
                                                         onChange={handleOptionChange4}
                                                     />


                                                     <span className='checkmark4'></span>
                                                     <p className='show_get_consultation_popup_check_input_title'>
                                                         {/*Мобильное приложение*/}
                                                         {translate('form_service4')}
                                                     </p>
                                                 </label>
                                                 <label className='container5'>
                                                     <input
                                                         type='checkbox'
                                                         value={select_service_type5}
                                                         checked={select_service_type5}
                                                         onChange={handleOptionChange5}
                                                     />


                                                     <span className='checkmark5'></span>
                                                     <p className='show_get_consultation_popup_check_input_title'>
                                                         {/*Другое*/}
                                                         {translate('form_service5')}
                                                     </p>
                                                 </label>
                                             </div>
                                         </div>
                                         <button type='button' className='send_get_consultation_btn' onClick={() => request()}>
                                             {/*Отправить заявку*/}
                                             {translate('submit_form_button')}
                                         </button>
                                     </div>

                                     <img className="show_get_consultation_popup_body_wrapper_right"  src={require('../../assets/img/24770152_101.png')}/>
                                 </form>


                             </div>

                         </div>
                     </div>

                 }

                 {waiting_for_you_popup &&
                 <div className='show_get_consultation_popup'>
                     <div className='show_get_consultation_popup_wrapper_parent'>
                         <div className='show_get_consultation_popup_wrapper'>
                             <button className='show_get_consultation_popup_close_btn'
                                     onClick={() =>
                                     {
                                         enableBodyScroll()
                                         setWaitingForYouPopup(false)
                                     }
                                     }>
                                 <img src={close_icon} alt=""/>
                             </button>
                             <div className='show_get_consultation_popup_logo'>
                                 <img src={logo} alt=""/>
                             </div>

                             <div className='show_get_consultation_popup_body_wrapper'>
                                 <div className='show_get_consultation_popup_body'>
                                     <div className='show_get_consultation_popup_input_field_wrapper'>
                                         <input type="text" className='show_get_consultation_popup_input_field' placeholder='Имя'/>
                                     </div>
                                     <div className='show_get_consultation_popup_input_field_wrapper'>
                                         <input type="text" className='show_get_consultation_popup_input_field' placeholder='Фамилия'/>
                                     </div>
                                     <div className='show_get_consultation_popup_input_field_wrapper'>
                                         <input type="text" className='show_get_consultation_popup_input_field' placeholder='Электронная почта'/>
                                     </div>
                                     <div className='show_get_consultation_popup_input_field_wrapper'>
                                         <input type="text" className='show_get_consultation_popup_input_field' placeholder='Номер телефона'/>
                                     </div>
                                 </div>

                                 <div className='show_get_consultation_popup_body_wrapper_right'>

                                 </div>
                             </div>


                             <button className='send_get_consultation_btn'>
                                 Отправить запрос
                             </button>
                         </div>

                     </div>
                 </div>
                 }

                 {success_popup &&
                     <div className='success_popup'>
                         <div className='success_popup_parent'>
                             <div className='success_popup_wrapper'>
                                 <button className='success_popup_close_btn'
                                         onClick={() =>
                                         {
                                             // props.enableBodyScroll()
                                             setSuccessPopup(false)
                                             setName('')
                                             setEmail('')
                                             setPhone('')
                                             setSelectServiceType1('')
                                             setSelectServiceType2('')
                                             setSelectServiceType3('')
                                             setSelectServiceType4('')
                                             setSelectServiceType5('')
                                             enableBodyScroll()
                                         }
                                         }>
                                     <img src={close_icon} alt=""/>
                                 </button>
                                 <div className='success_popup_logo'>
                                     <img src={logo} alt=""/>
                                 </div>
                                 <p className='success_popup_title'>
                                     {translate('call_order')}
                                 </p>
                                 <p className='success_popup_info'>
                                     {translate('call_order_text')  + ' ' +  phone  +  ' ' +  translate('thanks')}
                                 </p>
                             </div>
                         </div>
                 </div>
                 }

                 <Footer selectedLanguage={props.selectedLanguage}/>
             </div>
             {/*<Helmet>*/}
             {/*    <script>{`*/}
             {/*         (function (d,w) {*/}
             {/*           var n = d.getElementsByTagName("script")[0],*/}
             {/*               s = d.createElement("script"),*/}
             {/*               f = function () { n.parentNode.insertBefore(s, n); };*/}
             {/*           var dt = new Date().getTime();*/}
             {/*           s.type = "text/javascript";*/}
             {/*           s.id="plt_main";*/}
             {/*           s.src="https://prodalet.ru/bundles/api/main.js?h="+dt;*/}
             {/*           s.setAttribute("data-pltkey", "649fa85b64c84f245369f941");*/}
             {/*           s.setAttribute("data-plthost","https://prodalet.ru");*/}
             {/*           s.async = true;*/}
             {/*           if (w.opera == "[object Opera]") {*/}
             {/*             d.addEventListener("DOMContentLoaded", f, false);*/}
             {/*           } else {*/}
             {/*             f();*/}
             {/*           }*/}
             {/*         })(document,window);*/}
             {/*       `}*/}
             {/*    </script>*/}
             {/*</Helmet>*/}

             <div className='main_whatsapp_telegram_links_wrapper'>
                 <a href="https://t.me/JustCodeLLC" target='_blank'  className='main_whatsapp_telegram_link'>
                     <img src={telegram_iocn} alt="" />
                 </a>
                 {/*<a href="https://wa.me/+37477695677?text=Hi" className='main_whatsapp_telegram_link' target='_blank' >*/}
                 {/*    <img src={whatsapp_icon} alt="" />*/}
                 {/*</a>*/}
             </div>
         </>

     )


 }



