import { Link as LinkScroll } from 'react-scroll';
import React, { useState, useEffect, useRef } from 'react';
import '../../../assets/css/header_style.css';
import logo from '../../../assets/svg/logo.svg';
import {Link} from "react-router-dom";
import close_icon from '../../../assets/svg/close_icon.svg'
import footer_social_link_vk from "../../../assets/svg/footer_social_link1.svg";
import footer_social_link_fs from "../../../assets/svg/social_link..svg";
import footer_social_link_telegram from "../../../assets/svg/footer_social_link2.svg";
import footer_social_link_whatsApp from "../../../assets/svg/footer_social_link3.svg";
import ReactFlagsSelect from "react-flags-select";
import translations from '../../../translations/translations';


const sl_set = {
    infinite: true,
    dots: false,
    arrows: false,
    fade: true,
    waitForAnimate: false,
    pauseOnHover: false,
    beforeChange: (oldIndex, newIndex) => {
        this.setState({
            cur_pr: newIndex + 1,
            sliding: true,
        });
    },
    afterChange: (newIndex) => {
        this.setState({
            sliding: false,
        });
    },
};

export default function Header(props) {
    const [headerScroll, setHeaderScroll] = useState(false);
    const [show_mobile_menu, setShowMobileMenu] = useState(false);
    const [show_get_consultation_popup, setShowGetConsultationPopup] = useState(false);
    const { disableBodyScroll } = props;
    const { enableBodyScroll } = props;
    const [selected, setSelected] = useState(props.selectedLanguage == 'en' ? 'US' : props.selectedLanguage == 'ru' ? 'RU' : '' );
    const [language, setLanguage] = useState(props.selectedLanguage)
    const [select_service_type1, setSelectServiceType1] = useState('');
    const [select_service_type2, setSelectServiceType2] = useState('');
    const [select_service_type3, setSelectServiceType3] = useState('');
    const [select_service_type4, setSelectServiceType4] = useState('');
    const [select_service_type5, setSelectServiceType5] = useState('');

    const [isScrolled, setIsScrolled] = useState(false);


    const translate = (key) => {
        return translations[language][key] || key; // Return translated text or the key itself if translation not found
    };

    useEffect(() => {

        setTimeout(() => {
            props.setShowGetConsultationPopup(true)
            props.disableBodyScroll()
        },10000)
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setIsScrolled(scrollTop > 300);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleOptionChange1 = () => {
        setSelectServiceType1(!select_service_type1);

    };

    const handleOptionChange2 = () => {
        setSelectServiceType2(!select_service_type2);

    };

    const handleOptionChange3 = () => {
        setSelectServiceType3(!select_service_type3);

    };

    const handleOptionChange4 = () => {
        setSelectServiceType4(!select_service_type4);

    };

    const handleOptionChange5 = () => {
        setSelectServiceType5(!select_service_type5);

    };


    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }


        window.addEventListener('load', handleLoad);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLoad = () => {};

    const handleScroll = (ev) => {
        const scrollTop = window.scrollY;

        if (scrollTop > 0) {
            setHeaderScroll(true);
        } else {
            setHeaderScroll(false);
        }
    };

    const  pathName = () => {
        let name = window.location.pathname;
        console.log(name, 'nameee')
        return name
    }



    return (
        <>
            {isScrolled && (
                <style>{`
                  body {
                    padding-top: 80px;
                  }
                `}</style>
            )}
            <div  className={isScrolled ? 'fixed' : 'header'}>
                <div className='header_wrapper'>
                    <div className='header_logo_navigation_links_wrapper'>
                        <div className='header_logo'>
                            <Link to='/'  className='header_logo_link'>
                                <img src={logo}/>
                            </Link>
                        </div>
                        <div className="headers_navigation_links_wrapper">
                            <LinkScroll to='services' smooth={true}  duration={500} offset={-160} className="headers_navigation_link">
                                {/*Услуги*/}
                                {translate('services')}

                            </LinkScroll>
                            <LinkScroll to='mobile_applications' smooth={true} duration={500} offset={-150} className="headers_navigation_link">
                                {/*Портфолио*/}
                                {translate('portfolio')}

                            </LinkScroll>

                            <LinkScroll to='our_team' smooth={true} duration={500} offset={-160} className="headers_navigation_link">
                                {/*Команда*/}
                                {translate('team')}
                            </LinkScroll>
                            <LinkScroll to='footer' smooth={true} duration={500} offset={2020} className="headers_navigation_link">
                                {/*Контакты*/}
                                {translate('contacts')}
                            </LinkScroll>


                        </div>
                    </div>
                    <div className='header_mobile_menu_icon_btn_wrapper'>
                        <a className='top_phone_link' id='desktop_top_phone_link' href="tel:+374 (33) 28-28-23">
                            +374 (33) 28-28-23
                        </a>
                        <button
                            className='get_consultation_btn'
                            id='desktop_get_consultation_btn'
                            onClick={() => {
                                props.disableBodyScroll()
                                props.setShowGetConsultationPopup(true)
                            }}
                        >
                            {/*Оставить заявку*/}
                            {translate('submit_your_application')}

                        </button>
                        {/*<ReactFlagsSelect*/}
                        {/*    selected={selected}*/}
                        {/*    onSelect={(code) => setSelected(code)}*/}
                        {/*/>*/}

                        <ReactFlagsSelect
                            countries={["RU", "US"]}
                                selected={selected}
                                onSelect={(code) => {
                                    setSelected(code)

                                    let href = code == 'US' ? '/en' : code == 'RU' ? '/' : ''
                                    window.location.href = href;
                                }}
                            customLabels={{  RU: "RU", US: "US"}}
                            placeholder=""
                        />

                        <button
                            className='header_menu--opener'
                            onClick={() => {
                                setShowMobileMenu(true)
                                props.disableBodyScroll();
                            }}
                        >
                            <div className="line line_one"></div>
                            <div className="line line_two"></div>
                            <div className="line line_three"></div>
                        </button>
                    </div>

                    {show_mobile_menu &&
                        <div className='header_mobile_wrapper'>
                        <div className='header_mobile_wrapper_child'>
                            <button className='header_mobile_wrapper_close_btn' onClick={() => {
                                setShowMobileMenu(false)
                                props.enableBodyScroll()
                            }}>
                                <img src={close_icon} />
                            </button>
                            <div className="mobile_headers_navigation_links_wrapper">
                                <LinkScroll to='services' smooth={true} duration={500} offset={-160} className="headers_navigation_link"  onClick={() =>
                                {
                                    setShowMobileMenu(false)
                                    props.enableBodyScroll()
                                }}>
                                    {/*Услуги*/}
                                    {translate('services')}

                                </LinkScroll>
                                <LinkScroll to='mobile_applications' smooth={true} duration={500} offset={-150} className="headers_navigation_link"  onClick={() =>
                                {
                                    setShowMobileMenu(false)
                                    props.enableBodyScroll()
                                }}>
                                    {/*Кейсы*/}
                                    {translate('portfolio')}

                                </LinkScroll>

                                <LinkScroll to='our_team' smooth={true} duration={500} offset={-160} className="headers_navigation_link"  onClick={() =>
                                {
                                    setShowMobileMenu(false)
                                    props.enableBodyScroll()
                                }}>
                                    {/*Команда*/}
                                    {translate('team')}

                                </LinkScroll>
                                <LinkScroll to='footer' smooth={true} duration={500} offset={2020} className="headers_navigation_link"  onClick={() =>
                                {
                                    setShowMobileMenu(false)
                                    props.enableBodyScroll()
                                }}>
                                    {/*Контакты*/}
                                    {translate('contacts')}

                                </LinkScroll>

                            </div>
                            <div className='mobile_get_consultation_btn_social_links_wrapper'>

                                <div className='footer_first_item_social_links_wrapper'>
                                    <a href="https://www.facebook.com/JustCodeDev"
                                       target='_blank'
                                       className='footer_first_item_social_link'
                                    >
                                        <div className='top_item_info_social_link_svg_parent'>
                                            <img src={footer_social_link_fs} alt=""/>
                                        </div>
                                    </a>
                                    <a href="https://t.me/JustCodeLLC"
                                       className='footer_first_item_social_link'
                                       target='_blank'
                                    >
                                        <img src={footer_social_link_telegram}

                                        />
                                    </a>
                                    <a href="https://wa.me/+37477695677?text=Hi"
                                       className='footer_first_item_social_link'
                                       target='_blank'
                                    >
                                        <img src={footer_social_link_whatsApp}

                                        />
                                    </a>
                                </div>
                                {/*<button className='mobile_get_consultation_btn'*/}
                                {/*    onClick={() => {*/}
                                {/*        props.disableBodyScroll()*/}
                                {/*        props.setShowGetConsultationPopup(true)*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    {translate('submit_your_application')}*/}
                                {/*</button>*/}
                                <a className='top_phone_link' id='mobile_top_phone_link' href="tel:+374 (33) 28-28-23">
                                    +374 (33) 28-28-23
                                </a>

                                <button
                                    className='get_consultation_btn'
                                    id='mobile_get_consultation_btn'
                                    onClick={() => {
                                        props.disableBodyScroll()
                                        props.setShowGetConsultationPopup(true)
                                    }}
                                >
                                    {/*Оставить заявку*/}
                                    {translate('submit_your_application')}

                                </button>
                            </div>




                        </div>

                    </div>
                    }

                </div>

                {/*{show_get_consultation_popup &&*/}
                {/*    <div className='show_get_consultation_popup'>*/}
                {/*        <div className='show_get_consultation_popup_wrapper_parent'>*/}
                {/*            <div className='show_get_consultation_popup_wrapper'>*/}
                {/*                <button className='show_get_consultation_popup_close_btn'*/}
                {/*                        onClick={() =>*/}
                {/*                        {*/}
                {/*                            props.enableBodyScroll()*/}
                {/*                            setShowGetConsultationPopup(false)*/}
                {/*                        }*/}
                {/*                        }>*/}
                {/*                    <img src={close_icon} alt=""/>*/}
                {/*                </button>*/}
                {/*                <div className='show_get_consultation_popup_logo'>*/}
                {/*                    <img src={logo} alt=""/>*/}
                {/*                </div>*/}

                {/*                <div className='show_get_consultation_popup_body_wrapper'>*/}
                {/*                    <div className='show_get_consultation_popup_body'>*/}
                {/*                        <h1 className='show_get_consultation_popup_body_title'>*/}
                {/*                            /!*ОСТАВЬТЕ ЗАЯВКУ*!/*/}
                {/*                            {translate('submit_your_application')}*/}

                {/*                        </h1>*/}
                {/*                        <div className='show_get_consultation_popup_input_field_wrapper'>*/}
                {/*                            <div className='form_icon_wrapper'>*/}
                {/*                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="person"><g data-name="Layer 2"><path d="M12 11a4 4 0 1 0-4-4 4 4 0 0 0 4 4zm6 10a1 1 0 0 0 1-1 7 7 0 0 0-14 0 1 1 0 0 0 1 1z" data-name="person"></path></g></svg>*/}
                {/*                            </div>*/}

                {/*                            <input type="text" className='show_get_consultation_popup_input_field' placeholder={translate('form_your_name')}/>*/}
                {/*                        </div>*/}
                {/*                        <div className='show_get_consultation_popup_input_field_wrapper'>*/}

                {/*                            <div className='form_icon_wrapper form_icon_wrapper_email'>*/}
                {/*                                <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 96" id="email"><path d="M0 11.283V8a8 8 0 0 1 8-8h112a8 8 0 0 1 8 8v3.283l-64 40zm66.12 48.11a4.004 4.004 0 0 1-4.24 0L0 20.717V88a8 8 0 0 0 8 8h112a8 8 0 0 0 8-8V20.717z" data-name="Layer 2"></path></svg>*/}
                {/*                            </div>*/}

                {/*                            <input type="text" className='show_get_consultation_popup_input_field' placeholder='E-mail'/>*/}
                {/*                        </div>*/}
                {/*                        <div className='show_get_consultation_popup_input_field_wrapper'>*/}

                {/*                            <div className='form_icon_wrapper form_icon_wrapper_email'>*/}
                {/*                                <svg xmlns="http://www.w3.org/2000/svg" id="phone" x="0" y="0" version="1.1" viewBox="0 0 29 29" ><path d="m20.914 17.743-2.091 1.178a1.319 1.319 0 0 1-1.58-.217l-6.979-6.979a1.32 1.32 0 0 1-.217-1.58l1.178-2.091a1.978 1.978 0 0 0-.325-2.37L7.766 2.55a1.978 1.978 0 0 0-2.798 0L3.545 3.972a5.276 5.276 0 0 0-.793 6.446l.714 1.19a41.36 41.36 0 0 0 14.946 14.631l.141.081c2.102 1.201 4.699.851 6.382-.831l1.486-1.486a1.978 1.978 0 0 0 0-2.798l-3.136-3.136a1.978 1.978 0 0 0-2.371-.326z"></path></svg>*/}
                {/*                            </div>*/}
                {/*                            <input type="text" className='show_get_consultation_popup_input_field' placeholder={translate('form_phone')}/>*/}
                {/*                        </div>*/}
                {/*                        /!*<div className='show_get_consultation_popup_input_field_wrapper'>*!/*/}
                {/*                        /!*    <input type="text" className='show_get_consultation_popup_input_field' placeholder='Название вашей организации'/>*!/*/}
                {/*                        /!*</div>*!/*/}
                {/*                        /!*<div className='show_get_consultation_popup_input_field_wrapper'>*!/*/}
                {/*                        /!*    <input type="text" className='show_get_consultation_popup_input_field' placeholder='Чем вы занимаетесь?'/>*!/*/}
                {/*                        /!*</div>*!/*/}
                {/*                        <div className='show_get_consultation_popup_checkbox_inputs_wrapper'>*/}
                {/*                            <p className='show_get_consultation_popup_check_input_title_main'>*/}
                {/*                                /!*Выберите услугу*!/*/}
                {/*                                {translate('choose_a_service')}*/}
                {/*                            </p>*/}
                {/*                            <div className='show_get_consultation_popup_checkbox_inputs_wrapper_parent'>*/}
                {/*                                <label className='container1'>*/}
                {/*                                    <input*/}
                {/*                                        type='checkbox'*/}
                {/*                                        value={select_service_type1}*/}
                {/*                                        checked={select_service_type1}*/}
                {/*                                        onChange={handleOptionChange1}*/}
                {/*                                    />*/}


                {/*                                    <span className='checkmark1'></span>*/}
                {/*                                    <p className='show_get_consultation_popup_check_input_title'>*/}
                {/*                                        /!*Разработка сайта*!/*/}
                {/*                                        {translate('form_service1')}*/}
                {/*                                    </p>*/}
                {/*                                </label>*/}
                {/*                                <label className='container2'>*/}
                {/*                                    <input*/}
                {/*                                        type='checkbox'*/}
                {/*                                        value={select_service_type2}*/}
                {/*                                        checked={select_service_type2}*/}
                {/*                                        onChange={handleOptionChange2}*/}
                {/*                                    />*/}


                {/*                                    <span className='checkmark2'></span>*/}
                {/*                                    <p className='show_get_consultation_popup_check_input_title'>*/}
                {/*                                        /!*Разработка фирменного стиля*!/*/}
                {/*                                        {translate('form_service2')}*/}
                {/*                                    </p>*/}
                {/*                                </label>*/}
                {/*                                <label className='container3'>*/}
                {/*                                    <input*/}
                {/*                                        type='checkbox'*/}
                {/*                                        value={select_service_type3}*/}
                {/*                                        checked={select_service_type3}*/}
                {/*                                        onChange={handleOptionChange3}*/}
                {/*                                    />*/}


                {/*                                    <span className='checkmark3'></span>*/}
                {/*                                    <p className='show_get_consultation_popup_check_input_title'>*/}
                {/*                                        /!*Продвижение сайта*!/*/}
                {/*                                        {translate('form_service3')}*/}
                {/*                                    </p>*/}
                {/*                                </label>*/}
                {/*                                <label className='container4'>*/}
                {/*                                    <input*/}
                {/*                                        type='checkbox'*/}
                {/*                                        value={select_service_type4}*/}
                {/*                                        checked={select_service_type4}*/}
                {/*                                        onChange={handleOptionChange4}*/}
                {/*                                    />*/}


                {/*                                    <span className='checkmark4'></span>*/}
                {/*                                    <p className='show_get_consultation_popup_check_input_title'>*/}
                {/*                                        /!*Мобильное приложение*!/*/}
                {/*                                        {translate('form_service4')}*/}
                {/*                                    </p>*/}
                {/*                                </label>*/}
                {/*                                <label className='container5'>*/}
                {/*                                    <input*/}
                {/*                                        type='checkbox'*/}
                {/*                                        value={select_service_type5}*/}
                {/*                                        checked={select_service_type5}*/}
                {/*                                        onChange={handleOptionChange5}*/}
                {/*                                    />*/}


                {/*                                    <span className='checkmark5'></span>*/}
                {/*                                    <p className='show_get_consultation_popup_check_input_title'>*/}
                {/*                                        /!*Другое*!/*/}
                {/*                                        {translate('form_service5')}*/}
                {/*                                    </p>*/}
                {/*                                </label>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <button className='send_get_consultation_btn'>*/}
                {/*                            /!*Отправить заявку*!/*/}
                {/*                            {translate('submit_form_button')}*/}
                {/*                        </button>*/}
                {/*                    </div>*/}

                {/*                    <img className="show_get_consultation_popup_body_wrapper_right"  src={require('../../../assets/img/24770152_101.png')}/>*/}
                {/*                </div>*/}


                {/*            </div>*/}

                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}

            </div>

        </>
    );
}
