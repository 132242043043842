const translations = {
    en: {
        submit_your_application: 'Get consultation',
        services: 'Services',
        portfolio: 'Portfolio',
        team: 'Team',
        contacts: 'Contacts',

        top_title: 'DEVELOPMENT OF SITES AND MOBILE APPS',
        auto_complite_text: 'Since 2018, we have gained considerable experience in the development of websites and mobile applications, as well as in promoting businesses.',

        artem_hakobjanyan: 'Artem Hakobjanyan',
        mob_dev_with_team: 'Full-Stack developer with team',


        services_title1_part: 'OUR',
        services_title2_part: 'EXPERT SERVICES',
        services_title3_part: 'FOR YOUR SUCCESS!',

        service_development_app_title: 'Mobile Application Development',
        service_development_app_description: 'Mobile App Development: Build quality and intuitive mobile apps for iOS and Android that meet your business needs.',

        service_development_website_title: 'Website development of any complexity',
        service_development_website_description: 'Development of sites of any complexity: the creation of unique and functional websites, adaptive for various devices, to achieve your goals on the Internet.',

        service_development_seo_title: 'SEO-promotion',
        service_development_seo_description: 'Website and content optimization to increase its visibility in search engines, attract the target audience and increase organic traffic.',

        service_development_reklam_title: 'Contextual advertising',
        service_development_reklam_description: 'Creation and management of advertising campaigns with targeted display of advertising in accordance with the interests and needs of the target audience.',

        service_development_texpodderzhka_title: 'Technical support, site acceleration, etc.',
        service_development_texpodderzhka_description: 'JustCode: expert technical support, optimization and acceleration of sites, solving technical problems for the efficient and smooth operation of your online project.',

        service_development_courses_title: 'Courses and trainings',
        service_development_courses_description: 'Our mission is to introduce students to the latest IT technologies with an emphasis on practical skills. We provide full trainings in web development, mobile development and design.',



        portfolio_title1: 'OUR',
        portfolio_title2: 'WORKS',
        portfolio_description: 'Portfolio: A reflection of our success and client satisfaction.',


        teams_title1: 'OUR',
        teams_title2: 'TEAM',
        teams_description: 'Together we are strong: our team of experts is ready to turn your ambitions into reality and succeed together with you',


        doverte_nam: 'TRUST US WITH ALL THE TASKS AND BOLDLY FOCUS ON YOUR BUSINESS, WELL TAKE THE REST!',

        our_mission: 'OUR MISSION',
        our_mission_desc1: 'We make your business stand out in the online world. Our specialization is the development and promotion of websites using SEO optimization, contextual and targeted advertising. We bring your site to the first positions of search engines, increasing its visibility and attracting the target audience.',
        our_mission_desc2: 'Our team of professionals is ready to help you achieve success and maximum impact in the online environment.',

        contact_address: 'Yerevan, 26a Movses Khorenatsi street',
        contact_phone: 'Phone',
        contact_email: 'E-mail',

        footer_text: 'JustCode Development Company Digital Agency © 2018 — 2023. All rights reserved.',


        artem_hakobjanyan_role: 'Owner/ Senior Developer',

        vitaly_hakobjanyan: 'Vitaly Hakobjanyan',
        vitaly_hakobjanyan_role: 'Project Manager',

        rima_hayrapetyan: 'Rima Hayrapetian',
        rima_hayrapetyan_role: 'Project manager',

        ani_koshkaryan: 'Ani Koshkaryan',
        ani_koshkaryan_role: 'Frontend developer',

        norayr_asoyan : 'Norayr Asoyan',
        norayr_asoyan_role : 'UI/UIX Designer',

        lianna_hakobyan: 'Lianna Hakobyan',
        lianna_hakobyan_role: 'QA engineer',

        mekhak_poghosyan: 'Mekhak Poghosyan',
        mekhak_poghosyan_role: 'Android/ios developer',

        shogik_galoyan: 'Shogik Galoyan',
        shogik_galoyan_role: 'Android/ios developer',


        lilit_mroyan: 'Lilit Mroyan',
        lilit_mroyan_role: 'Android/ios developer',


        mane_gevorgyan: 'Mane Gevorgyan',
        mane_gevorgyan_role: 'Frontend developer',


        arman_alekyan: 'Arman Alekyan',
        arman_alekyan_role: 'Laravel developer',

        karen_aroyan: 'Karen Aroyan',
        karen_aroyan_role: 'Laravel developer',

        form_your_name: 'Your name',
        form_phone: 'Phone number',

        choose_a_service: 'Choose a service',

        form_service1: 'Website development',
        form_service2: 'Branding',
        form_service3: 'Website promotion',
        form_service4: 'Mobile Application',
        form_service5: 'Other',

        submit_form_button: 'Send form',

        more: 'More',
        call_order: 'Call ordered',
        call_order_text: 'We will call you back as soon as possible at',
        thanks: 'Thank you',

    },
    ru: {
        submit_your_application: 'Оставить заявку',
        services: 'Услуги',
        portfolio: 'Портфолио',
        team: 'Команда',
        contacts: 'Контакты',

        top_title: 'РАЗРАБОТКА САЙТОВ И МОБИЛЬНЫХ ПРИЛОЖЕНИЙ',
        auto_complite_text: 'Начиная с 2018 года, мы накопили значительный опыт в разработке веб-сайтов и мобильных приложений, а также в продвижении бизнесов.',
        artem_hakobjanyan: 'Артем Акобджанян',
        mob_dev_with_team: 'Full-Stack разработчик с командой',


        services_title1_part: 'НАШИ',
        services_title2_part: 'ЭКСПЕРТНЫЕ УСЛУГИ',
        services_title3_part: 'ДЛЯ ВАШЕГО УСПЕХА!',

        service_development_app_title: 'Разработка мобильных приложений',
        service_development_app_description: 'Разработка мобильных приложений: создание качественных и интуитивно понятных мобильных приложений для iOS и Android, удовлетворяющих потребностям вашего бизнеса.',

        service_development_website_title: 'Разработка сайтов любой сложности',
        service_development_website_description: 'Разработка сайтов любой сложности: создание уникальных и функциональных веб-сайтов, адаптивных под различные устройства, для достижения ваших целей в интернете.',

        service_development_seo_title: 'SEO-продвижение',
        service_development_seo_description: 'Оптимизация сайта и контента для повышения его видимости в поисковых системах, привлечения целевой аудитории и увеличения органического трафика.',

        service_development_reklam_title: 'Контекстная реклама',
        service_development_reklam_description: 'Cоздание и управление рекламными кампаниями с точечным показом рекламы в соответствии с интересами и запросами целевой аудитории.',

        service_development_texpodderzhka_title: 'Техподдержка, ускорение сайтов и др.',
        service_development_texpodderzhka_description: 'JustCode: экспертная техподдержка, оптимизация и ускорение сайтов, решение технических проблем для эффективной и бесперебойной работы вашего онлайн-проекта.',

        service_development_courses_title: 'Курсы и тренинги',
        service_development_courses_description: 'Наша миссия - познакомить студентов с новейшими ИТ-технологиями с упором на практические навыки. Мы проводим полные тренинги по веб-разработке, мобильной разработке и дизайну.',

        portfolio_title1: 'НАШИ',
        portfolio_title2: 'РАБОТЫ',
        portfolio_description: 'Портфолио: Отражение наших успехов и клиентской удовлетворенности.',

        teams_title1: 'НАША',
        teams_title2: 'КОМАНДА',
        teams_description: 'Вместе мы сила: наша команда экспертов готова воплотить ваши амбиции в реальность и преуспеть вместе с вами',

        doverte_nam: 'ДОВЕРЬТЕ НАМ ВСЕ ЗАДАЧИ И СМЕЛО СОСРЕДОТОЧЬТЕСЬ НА СВОЕМ БИЗНЕСЕ, ОСТАЛЬНОЕ МЫ ВОЗЬМЕМ НА СЕБЯ!',

        our_mission: 'НАША МИССИЯ',
        our_mission_desc1: 'Мы делаем ваш бизнес заметным в онлайн-мире. Наша специализация - разработка и продвижение сайтов с помощью SEO-оптимизации, контекстной и таргетированной рекламы. Мы приводим ваш сайт на первые позиции поисковых систем, увеличивая его видимость и привлекая целевую аудиторию.',
        our_mission_desc2: 'Наша команда профессионалов готова помочь вам достичь успеха и максимального воздействия в онлайн-среде.',

        contact_address: 'Ереван, 26а улица Мовсеса Хоренаци',
        contact_phone: 'Телефон',
        contact_email: 'Эл. почта',

        footer_text: 'Digital-агентство JustCode Development Company © 2018 — 2023. Все права защищены.',


        artem_hakobjanyan_role: 'Генеральный директор',

        vitaly_hakobjanyan: 'Виталий Акобджанян',
        vitaly_hakobjanyan_role: 'Руководитель проекта',

        rima_hayrapetyan: 'Рима Айрапетян',
        rima_hayrapetyan_role: 'Руководитель проекта',

        ani_koshkaryan: 'Ани Кошкарян',
        ani_koshkaryan_role: 'Фронтенд разработчик',


        norayr_asoyan : 'Норайр Асоян',
        norayr_asoyan_role : 'UI/UIX Дизайнер',

        lianna_hakobyan: 'Лианна Акопян',
        lianna_hakobyan_role: 'QA engineer',

        mekhak_poghosyan: 'Мехак Погосян',
        mekhak_poghosyan_role: 'Android/ios разработчик',

        shogik_galoyan: 'Шогик Галоян',
        shogik_galoyan_role: 'Android/ios разработчик',

        lilit_mroyan: 'Лилит Мроян',
        lilit_mroyan_role: 'Android/ios разработчик',

        mane_gevorgyan: 'Мане Геворгян',
        mane_gevorgyan_role: 'Фронтенд разработчик',

        arman_alekyan: 'Arman Alekyan',
        arman_alekyan_role: 'Laravel разработчик',

        karen_aroyan: 'Karen Aroyan',
        karen_aroyan_role: 'Laravel разработчик',

        form_your_name: 'Ваше имя',
        form_phone: 'Номер телефона',

        choose_a_service: 'Выберите услугу',

        form_service1: 'Разработка сайта',
        form_service2: 'Разработка фирменного стиля',
        form_service3: 'Продвижение сайта',
        form_service4: 'Мобильное приложение',
        form_service5: 'Другое',

        submit_form_button: 'Отправить заявку',
        more: 'Подробнее',
        call_order: 'ЗВОНОК ЗАКАЗАН',
        call_order_text: 'Мы вам перезвоним в ближайшее время по номеру',
        thanks: 'Спасибо.'


    },
    // Add more language translations as needed
};

export default translations;
